<template>
  <div class="table-plans">
    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='show-log'
      size='lg'
      scrollable
      hide-footer
      title='Transferência de plano entre usuários'
    >
      <section class='modal-font-family'>
        <dialog-confirmation
          :description="description"
          :loading="loadingTransferPlan"
          :is-open='openDialog'
          @close='openDialog = false'
          @confirmed='transferPlan'
        />

        <div class="d-flex w-100">
          <div class="d-flex w-50 mr-3" style='min-height: 300px' >
            <vue-bootstrap-typeahead
              class="w-100"
              v-model="userSelect"
              :maxMatches="maxMatchesSelect"
              :serializer="(item) => item.username"
              :data="users"
              sort-variant="length"
              @hit="selectByUser($event)"
              @input="clear"
              placeholder="Digite o e-mail do usuário para pesquisar"
              ref="userBaseAutocomplete"
            />
            <b-spinner v-if='loading' class='loading px-2' variant="primary" label="Spinning"></b-spinner>
          </div>

          <div class="w-50" v-if='planToBeTransferred'>
            Plano: {{ planName }} <br />
            Data de assinatura: {{ planToBeTransferred.createdAt | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm') }}
            <br />
            <span v-if="planToBeTransferred.lastPayment">Última renovação: {{ planToBeTransferred.lastPayment | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm')}}<br/></span>

            <span>Validade do plano: {{ planToBeTransferred.dueDate | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm') }}<br/></span>
            <div v-if="planToBeTransferred.plan === 'MO1HO'">
              Data de vencimento da Próxima cobrança: {{ planToBeTransferred.dueDate | moment('timezone', 'America/Sao_Paulo', 'DD/MM/YYYY HH:mm')}}<br/>
            </div>

            Situação do plano: {{ statusToPtBr(planToBeTransferred.status) }}<br/>

            <div v-if="planToBeTransferred.status === 'pending' && planToBeTransferred.bankSlip">
              Segunda via do boleto: <a :href="planToBeTransferred.bankSlip" target="_blank">aqui</a> <br/>
            </div>

            Dias de utilização: {{ new Date() | moment('diff', planToBeTransferred.createdAt, 'days') }} <br/>
          </div>
        </div>
      </section>
    </b-modal>

    <b-modal
      header-bg-variant="info"
      header-text-variant="light"
      ref='registerSubordinateUserAccess'
      id='registerSubordinateUserAccess'
      size='lg'
      scrollable
      hide-footer
      title='Cadastrar acessos para usuários subordinados'
    >
      <section class='modal-font-family'>
        <dialog-confirmation
          :description="description"
          :loading="loadingTransferPlan"
          :is-open='openDialog'
          @close='openDialog = false'
          @confirmed='transferPlan'
        />

        <section>
          <base-input-text
            class='w-100'
            v-model="amountPurchased"
            label="Liberar quantos acessos para usuários subordinados?"
            placeholder="Digite a quantidade acessos"
            type="number"
            block
          />
        </section>

        <section class='d-flex w-100 mt-4'>
          <b-button
            @click="closeModalRegisterSubordinateUserAccess()"
            class='w-50 mr-2'
            variant='danger'
            block
          >
            Cancelar
          </b-button>

          <b-overlay
            :show='loading'
            rounded
            opacity='0.6'
            spinner-small
            spinner-variant='primary'
            class='w-50 justify-content-center'
          >
            <b-button
              :disabled='amountPurchased < 1'
              class='btn_save'
              @click='createAccessCollaborator()'
              variant='success'
              block
            >
              Salvar
            </b-button>
          </b-overlay>
        </section>
      </section>
    </b-modal>

    <modal-confirm-action
      :loading='loading'
      :description='descriptionModalConfirmCancelPlan'
      :title='titleModalConfirmCancelPlan'
      @confirmed='cancelPlan'
    />

    <h3>Planos</h3>

    <base-table
      v-if="plans.length > 0 && plansMeta"
      :columns="columns"
      :data="plans"
      :total-items="plansMeta.total"
      :start-items="plansMeta.skip + 1"
      :max-items="plansMeta.limit"
      @set-page="(page) => $emit('change-plans', page)"
    >
      <template v-slot:body-createdAt="row">
        {{ row.item.createdAt ? formatDate(row.item.createdAt) : '' }}
      </template>

      <template v-slot:body-dueDate="row">
        {{ row.item.dueDate ? formatDateDueDate(row.item.dueDate) : '' }}
      </template>

      <template v-slot:body-originalDueDate="row">
        <span v-if='row.item && row.item.originalDueDate'>
          {{ formatDateDueDate(row.item.originalDueDate) }}
        </span>
        <span v-else-if='row.item && row.item.dueDate'>
          {{ formatDateDueDate(row.item.dueDate) }}
        </span>
        <span v-else>
          -
        </span>
      </template>

      <template v-slot:body-method="row">
        {{ PAYMENT_METHODS[row.item.method] }}
      </template>

      <template  v-slot:body-actions="row">
        <section class='d-flex' v-if='!readOnly'>
          <section
            v-if="showTransferPlanButton(row.item)"
            class='mr-2'
          >
            <b-popover target="transferPlan" triggers="hover" placement="top">
              <template #title>Transferir plano</template>
            </b-popover>

            <b-button
              id="transferPlan"
              variant="outline-success"
              class='icon-transfer'
              @click="showModalTransferPlan(row.item)"
            >
              <b-icon icon='arrow-clockwise'></b-icon>
            </b-button>
          </section>

          <section
            v-if="showCancelPlanButton(row.item)"
            class='mr-2'
          >
            <b-popover target="cancelPlan" triggers="hover" placement="top">
              <template #title>Cancelar plano</template>
            </b-popover>

            <b-button
              id="cancelPlan"
              variant="outline-danger"
              class='icon-transfer'
              @click="showModalCancelPlan(row.item)"
            >
              <b-icon icon='x-circle'></b-icon>
            </b-button>
          </section>

          <section
            v-if="showTransferPlanButton(row.item)"
            class='mr-2'
          >
            <b-popover target="btnRegisterSubordinateUserAccess" triggers="hover" placement="top">
              <template #title>Cadastrar acessos para usuários subordinados</template>
            </b-popover>

            <b-button
              id="btnRegisterSubordinateUserAccess"
              variant="outline-success"
              class='icon-transfer'
              @click="showModalRegisterSubordinateUserAccess(row.item)"
            >
              <b-icon icon='plus'></b-icon>
            </b-button>
          </section>
        </section>
      </template>
    </base-table>
    <span v-else>Usuário não tem planos!</span>
  </div>
</template>

<script>
import { format } from 'date-fns';
import _ from 'underscore'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import { mapActions } from 'vuex'
import DialogConfirmation from '@/components/DialogConfirmation.vue'
import ModalConfirmAction from '@/components/ModalConfirmAction.vue'
import moment from 'moment/moment'
export default {
  name: 'TablePlans',
  data(){
    return {
      userSelect: '',
      descriptionModalConfirmCancelPlan: '',
      titleModalConfirmCancelPlan: '',
      planCancel: null,
      planRegisterSubordinateUserAccess: null,
      amountPurchased: null,
      userTransferPlan: {},
      planToBeTransferred: '',
      userIdReceiveThePlan: '',
      description: '',
      users: [],
      loading: false,
      loadingTransferPlan: false,
      openDialog: false,
      maxMatchesSelect: 100,
      columns: [
        { key: 'plan', value: 'Plano' },
        { key: 'status', value: 'Status'},
        { key: 'method', value: 'Meio de Pagamento' },
        { key: 'createdAt', value: 'Criado em' },
        { key: 'dueDate', value: 'Data de vencimento + Dias extras (se houver)' },
        { key: 'originalDueDate', value: 'Data de vencimento' },
        { key: 'actions', value: 'Ações'},
      ],
      PAYMENT_METHODS: {
        'bank_slip': 'Boleto',
        'credit_card': 'Cartão'
      }
    }
  },
  components: {
    ModalConfirmAction,
    VueBootstrapTypeahead,
    DialogConfirmation,
  },
  props: {
    user: { type: Object, require: true },
    plans: { type: Array, require: true },
    plansMeta: { type: Object, require: true },
    readOnly: { type: Boolean, required: true },
  },
  emits: ['change-plans'],
  computed: {
    planName(){
      if(this.planToBeTransferred.plan === 'MO1HO')
        return 'Mensal'
      if(this.planToBeTransferred.plan === 'AN1HO')
        return 'Anual'
      if(this.planToBeTransferred.plan === 'PROMO')
        return 'Promocional'
      if(this.planToBeTransferred.plan === 'TRIAL')
        return 'Teste grátis'
      return 'Personalizado'
    }
  },
  methods: {
    ...mapActions('user', {
      handleGetUserById: 'handleGetUserById',
      transfer: 'transferPlan',
      handleGetUser: 'handleGetUser',
    }),
    ...mapActions('userPlans', {
      handleGetUserPlans: 'handleGetUserPlans',
      handleCancelUserPlan: 'handleCancelUserPlan',
    }),
    ...mapActions('acessCollaborator', {
      handleCreateAccessCollaborator: 'handleCreateAccessCollaborator',
    }),

    statusToPtBr(status) {
      if (status === 'paid') return 'ativo'
      if (status === 'pending') return 'aguardando pagamento'
      if (status === 'outdated') return 'cancelado por vencimento da cobrança'
      else return 'vencido'
    },

    showTransferPlanButton(plan){
      return plan.status === 'paid' && (plan.plan === 'COPY' || plan.plan === 'AN1HO' || plan.plan === 'PROMO')
    },

    showCancelPlanButton(plan){
      return (plan.status === 'paid' || plan.status === 'pending')
    },

    clear() {
      if (this.$refs.userBaseAutocomplete.inputValue === '') {
        this.userSelect = undefined
        this.disabled = true
      }
    },

    async selectByUser($event) {
      this.userIdReceiveThePlan = $event
      this.openDialog = true
      this.description = `Deseja realmente transferir o plano ${this.planName}, do usuário ${this.user.username} para o ${this.userIdReceiveThePlan.username}`
    },

    async transferPlan(){
      try {
        this.loadingTransferPlan = true
        const dataTransfer = {
          userIdReceiveThePlan : this.userIdReceiveThePlan,
          planToBeTransferred : this.planToBeTransferred,
          userTransferPlan :this.user,
        }

        const { message, errors } = await this.transfer(dataTransfer)
        let variant = 'success'

        if(errors){
          variant = 'danger'
        }

        this.openDialogReleaseTrial = false
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        })
        this.openDialog = false
        this.loadingTransferPlan = false
        await this.handleGetUser(this.user.id)
        await this.handleGetUserPlans({ userId: this.user.id })
        this.close();
      } catch (e) {

      }
    },

    show() {
      this.$refs['show-log'].show()
      setTimeout(() => {
        this.$refs.userBaseAutocomplete.$el.querySelector('input').addEventListener('keyup', (e) => {
          this.$refs.userBaseAutocomplete.inputValue = this.$refs.userBaseAutocomplete.inputValue.replace(/\s+/g, "")
          this.userSelect = this.$refs.userBaseAutocomplete.inputValue
        })
      }, 1000)
    },

    close() {
      this.$refs['show-log'].hide()
    },

    showModalTransferPlan(plan){
      this.planToBeTransferred = plan
      this.show()
      this.userSelect = '';
    },

    showModalRegisterSubordinateUserAccess(plan){
      this.amountPurchased = null
      this.planRegisterSubordinateUserAccess = plan
      this.$refs.registerSubordinateUserAccess.show()
    },

    closeModalRegisterSubordinateUserAccess(){
      this.$refs.registerSubordinateUserAccess.hide()
    },

    async createAccessCollaborator(plan) {
      try {
        this.loading = true
        const accessCollaborator = {
          planId: this.planRegisterSubordinateUserAccess._id,
          amountPurchased: this.amountPurchased,
        }

        const { data, status } = await this.handleCreateAccessCollaborator(accessCollaborator)
        let message = data.message
        let variant = 'danger'

        if (status === 201){
          message = data.message;
          variant = 'success';
        }

        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        })

        this.closeModalRegisterSubordinateUserAccess()
        await this.handleGetUser(this.user.id)
        await this.handleGetUserPlans({ userId: this.user.id })
        this.loading = false
      } catch (e) {

      }
    },

    showModalCancelPlan(plan){
      this.planCancel = plan
      this.titleModalConfirmCancelPlan = 'Cancelar plano'
      this.descriptionModalConfirmCancelPlan = `Esta ação vai cancelar o plano do usuário: <strong>${this.user.username}</strong>. <br/> Deseja realmente excutar essa ação?`
      this.$bvModal.show('modalConfirmAction')
    },

    async cancelPlan(){
      try {
        this.loading = true
        const cancelPlan = {
          planId : this.planCancel._id,
          userId : this.user.id,
        }

        const { message, errors } = await this.handleCancelUserPlan(cancelPlan)
        let variant = 'success'

        if(errors){
          variant = 'danger'
        }

        this.openDialogReleaseTrial = false
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        })
        await this.handleGetUser(this.user.id)
        await this.handleGetUserPlans({ userId: this.user.id })
        this.loading = false
        this.$bvModal.hide('modalConfirmAction')
      } catch (e) {

      }
    },

    formatDate(date){
      //date = date.split('T')[0].replace('-', '/')
      return format(new Date(date), 'dd/MM/yyyy HH:mm:ss')
    },
    formatDateDueDate(date) {
      const dueDate = new Date(date)
      return moment(new Date(dueDate.getFullYear(), dueDate.getMonth(), dueDate.getDate(), 23, 59, 59)).format('DD/MM/YYYY HH:mm:ss')
    },
  },
  watch: {
    userSelect: _.debounce(async function (newQuery) {
      if (!newQuery || newQuery.length < 3) return
      this.loading = true
      const users = (await this.$api.get(`admin/users/userByUserName/${newQuery}`)).data
      this.users = users
      if(!users || users.length <= 0) {
        this.$bvToast.toast(`Usuário com email: '${newQuery}' não esta cadastrado na base de dados!`, {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
        })
      }
      this.loading = false
    }, 1500),
  },
}
</script>

<style lang="scss" scoped>
.loading{
  width: 2rem;
  height: 2rem;
  margin-left: 10px;
}
.icon-transfer{
  cursor: pointer;
  padding: 2px;
  width: 45px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.table-plans {
  display: flex;
  flex-direction: column;
  gap: 4px;
  h3 {
    font-weight: bold;
  }
}
</style>
